<template>
<!--本页是案例页面的中转页面，用于处理刷新问题-->

</template>

<script>
export default {
  name: "CaseTemp",
  mounted() {
    this.$router.push({path:'/Case',query: {caseType: this.$route.query.caseType}});
  }
}
</script>

<style scoped>

</style>